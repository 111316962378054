import React from "react";
import "./footer2.scss";
import logo from "../../media/khilogo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Kota Harapan Indah</h2>
          </div>
        </div>

        <div className="container-deskripsi">
          <h1>Get In Touch</h1>
          <p>
            Medan Satria, Kecamatan Medan Satria, Kota Bks, Jawa Barat 17131
          </p>
          <div className="contact">Contact us : +6287782793628</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">
        KOTA HARAPAN INDAH | 2023
        <div className="pw">Powered by Linktown</div>
      </div>
    </div>
  );
};

export default footer;
